.list-items{
    padding: 0rem;
}

.item{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    margin-top: 1rem;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    list-style-type: none;
    
}

.anchor {
    color: black;
}

.anchor:hover {
    color: yellowgreen;
  }
