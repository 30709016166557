.container {
    padding-top: 30px;
}

.profile {
    width: 80x;
    float: left;
    padding-right: 10px;
}

.image {
    width: 80px;
    border-radius: 50%;
}

.caption{
    font-size: 0.9rem;
    margin-top: 10px;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.description{
    color: rgb(114, 113, 113);
    font-size: 0.8rem;
}

@media (min-width: 768px){
    .profile {
        width: 120px;
        float: none;
    }

    .image {
        width: 100%;
    }
}