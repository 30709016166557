/* .px-6 {
    padding-right: 5rem !important;
    color: yellow;
  } */

  @media (min-width: 768px) {
    .pt-md-6,
    .py-md-6 {
      padding-top: 5rem !important;
    }

    .pr-md-6,
    .px-md-6 {
      padding-right: 5rem !important;
    }

    .pb-md-6,
    .py-md-6 {
      padding-bottom: 5rem !important;
    }

    .pl-md-6,
    .px-md-6 {
      padding-left: 5rem !important;
    }
}

@media (min-width: 992px) {
    .pt-lg-10,
    .py-lg-10 {
      padding-top: 10rem !important;
    }

    .pr-lg-10,
    .px-lg-10 {
      padding-right: 10rem !important;
    }

    .pb-lg-10,
    .py-lg-10 {
      padding-bottom: 10rem !important;
    }

    .pl-lg-10,
    .px-lg-10 {
      padding-left: 10rem !important;
    }
}

/* otherwise, leaflet zoom button has the same z-index as 1000 and cover the dropdown list */
.dropdown-menu{
  z-index: 1001 !important;
}