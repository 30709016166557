@media (max-width: 992px) {
  .description {
    min-height: 120px;
  }
}

@media (max-width: 576px) {
    .description {
      min-height: 150px;
    }
  }
