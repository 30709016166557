.title {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    /* font-size: 1.5rem; */
    text-align: center;
}

.heading3-main-page{
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.tool-set{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.profile{
    height: 300px;
    width: 300px;
    display: block;
    margin: 10px auto;
    border-radius: 50%;
}