.code {
  font-family: 'Courier New', Courier, monospace;
  color: black;
}

.single-line {
  background-color: rgba(121, 121, 121, 0.2);
  border-radius: 6px;
  padding: 0.2em 0.4em;
}

.multi-line {
  background-color: rgba(121, 121, 121, 0.2);
  padding-left: 5px;
  border-radius: 6px;
  max-height: 300px;
  overflow-x: auto;
  margin-bottom: 10px;
}

.multi-line code {
  /* color: black; */
  white-space: nowrap;
}
